import React from 'react';
import Svg from './Svg';

export default function FormationPicto(props) {
  return (
    <Svg
      {...props}
      d="M741.225 404.276 624.314 278.468 36.954 820.457l116.911 125.808 587.36-541.989ZM952.136 61.284H780.1v1.85H150.397v167.732H780.1v647.077h172.036V61.284Z"
    />
  );
}
