import { Card, Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

type TSimpleCardProps = {
  width?: any;
  accentColor?: string;
  bg?: string;
  mt?: string;
  mb?: any;
  flexGrow?: number;
  height?: any;
  mx?: any;
  padding?: any;
  aspectRatio?: any;
  hoveredBg?: any;
  gap?: string | number;
  children?: ReactNode;
};

export default function SimpleCard({
  width = null,
  accentColor = 'brand.100',
  bg = 'white',
  mt = '0.625rem',
  mb = null,
  flexGrow = 1,
  children,
  height = null,
  mx = null,
  padding = { base: '1.25rem 1rem', md: '1.625rem 1.25rem' },
  aspectRatio = null,
  hoveredBg = null,
  gap = '1rem',
}: TSimpleCardProps) {
  return (
    <Card
      flexShrink={0}
      flexGrow={flexGrow}
      boxShadow="none"
      background={bg}
      position="relative"
      borderStyle="solid"
      height={height ? height : 'fit-content'}
      borderColor="brand.100"
      mt={mt}
      mb={mb}
      mx={mx}
      borderWidth="0 1px 1px 1px"
      borderRadius="0 0 1.875rem 0"
      width={width ? width : ['17.5rem', '25rem', 'revert']}
      overflow="hidden"
      aspectRatio={aspectRatio}
      _hover={{ backgroundColor: hoveredBg }}
    >
      <Box
        minHeight=".625rem"
        overflow="hidden"
        content=""
        mx="-1px"
        backgroundColor={accentColor}
        gap={gap}
      />
      <Flex
        gap={gap}
        direction="column"
        flexShrink="0"
        height="100%"
        padding={padding}
      >
        {children}
      </Flex>
    </Card>
  );
}
