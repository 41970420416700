import { Box, Icon } from '@chakra-ui/react';

export default function SliderIcon({ icon, color, blackIcon = null }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="3rem"
      height="3rem"
      borderRadius="full"
      background="white"
      border="1px solid"
      borderColor={color}
      transition="all 0.25s ease-in-out"
      cursor="pointer"
      _hover={{
        background: color,
        '& > svg': {
          color: blackIcon ? 'black' : 'white',
        },
      }}
    >
      <Icon as={icon} boxSize="2rem" color={blackIcon ? 'black' : color} />
    </Box>
  );
}
