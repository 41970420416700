import { Box } from '@chakra-ui/react';
import MouettePicto from '../../../presentational/img/icons/MouettePicto';

export default function HeroBackgroundImage({ image, color = "brand.100", picto = null, pictoMarginTop = null, pictoTopBase = null, pictoTopSm = null, pictoLeftBase = null }) {
  return (
    <>
      {picto &&
        <Box
          zIndex={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          opacity=".08"
          left={{ base: pictoLeftBase, sm: '-2vw' }}
          height={{ base: '4rem', sm: '16rem', md: '30rem' }}
          aspectRatio={1}
          marginTop={pictoMarginTop}
          top={{ base: pictoTopBase, sm: pictoTopSm, md: 'revert' }}
        >
          <Box transform={{ base: 'scale(.2)', sm: 'scale(0.4)', md: 'revert' }}>
            {picto}
          </Box>
        </Box>
      }
      {!picto &&
        <Box
          zIndex={0}
          display={{ base: "none", md: "block" }}
          position="absolute"
          opacity=".08"
          bottom="-1vw"
          left="-20vw"
          height="47vw"
          aspectRatio={1}
        >
          <MouettePicto freeIconColor="white" iconWidth="100%" />
        </Box>
      }
      <Box
        zIndex={{ md: 0 }}
        display={{ base: "none", md: "block" }}
        backgroundImage={{ md: `url(${image})` }}
        backgroundRepeat={{ md: "no-repeat" }}
        backgroundSize={{ md: "cover" }}
        backgroundPosition={{ md: "50% 50%" }}
        position={{ md: "absolute" }}
        top={{ md: 0 }}
        right={{ md: 0 }}
        bottom={{ md: 0 }}
        width={{ md: "50vw" }}
        aria-hidden="true"
      >
        <Box
          width={{ md: "100%" }}
          height={{ md: "100%" }}
          color={{ md: color }}
          position={{ md: "relative" }}
          left={{ md: "-1px" }}
        >
          <svg
            width="5000"
            height="9065"
            viewBox="0 0 5000 9065"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: "100%", width: "auto" }}
          >
            <path d="M0 0.956055V9065L5000 0.956055H0Z" fill="currentColor" />
          </svg>
        </Box>
      </Box>
    </>
  );
}