import { Box, Flex, Image } from '@chakra-ui/react';
import SimpleCard from './SimpleCard';
import { useMemo } from 'react';
import BackgroundCommunaute from '../../../../public/components/card/card_bg_communaute.svg';
import BackgroundBrand from '../../../../public/components/card/card_bg_brand.svg';
import BackgroundFormation from '../../../../public/components/card/card_bg_formation.svg';
import BackgroundRessource from '../../../../public/components/card/card_bg_ressource.svg';

export default function ComplexCard({
  image = null,
  imageAlt = null,
  width = null,
  accentColor,
  children,
  mx = null,
  imageMaxHeight = null,
  flexGrow = 1,
  height = 'fit-content',
}) {
  const bgCard = useMemo(() => {
    if (
      accentColor.includes('tournesol') ||
      accentColor.includes('sunflower')
    ) {
      return BackgroundCommunaute.src;
    } else if (accentColor.includes('tuile')) {
      return BackgroundRessource.src;
    } else if (accentColor.includes('menthe') || accentColor.includes('mint')) {
      return BackgroundFormation.src;
    } else {
      return BackgroundBrand.src;
    }
  }, [accentColor]);

  return (
    <Flex
      direction="column"
      align="flex-end"
      flexShrink={0}
      width={width ? width : ['17.5rem', '25rem', '35rem']}
      pl="1rem"
      alignSelf="stretch"
      mx={mx ? mx : null}
    >
      <Box
        width="calc(100% + 1rem)"
        height={['10rem', '', '20rem']}
        maxHeight={imageMaxHeight}
        bg="white"
        border={!image ? 'solid 1px' : null}
        borderColor={accentColor}
      >
        <Image
          src={image ? image : bgCard}
          alt={imageAlt ? imageAlt : ''}
          width="100%"
          height="100%"
          objectFit={image ? 'cover' : null}
          maxWidth={!image ? '100%' : null}
          maxHeight={imageMaxHeight}
        />
      </Box>
      <SimpleCard
        width="100%"
        mt="-1.5rem"
        accentColor={accentColor}
        flexGrow={flexGrow}
        height={height}
        flexBasis={1}
      >
        {children}
      </SimpleCard>
    </Flex>
  );
}
