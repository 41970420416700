import React from 'react';
import styled from '@emotion/styled';

const Svg = styled.svg`
  display: inline-block;
  ${props => (props.cursor ? 'cursor: ' + props.cursor + ';' : null)};
  ${props => (props.verticalAlignMiddle ? 'vertical-align: middle;' : null)}
  ${props =>
    props.iconMarginRight
      ? 'margin-right: ' + props.iconMarginRight + ';'
      : null}
  ${props =>
    props.iconMarginLeft ? 'margin-left: ' + props.iconMarginLeft + ';' : null}
  width: ${props => props.iconWidth};
  min-width: ${props => props.iconWidth};
  height: ${props => props.iconWidth};
  max-width: 100%;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  ${props =>
    props.borderColor
      ? 'border: solid 1px ' + props.theme[props.borderColor] + ';'
      : null};
`;
const Path = styled.path`
  fill: ${props => {
    if (props.freeIconColor) {
      return props.freeIconColor;
    }
    if (props.iconColor) {
      return props.theme[props.iconColor];
    }
    return props.theme.lightColor;
  }};
`;
class SvgIcon extends React.Component {
  render() {
    return (
      <Svg
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg"
        iconWidth={this.props.iconWidth}
        iconMarginRight={this.props.iconMarginRight}
        iconMarginLeft={this.props.iconMarginLeft}
        iconAlt={this.props.iconAlt}
        cursor={this.props.cursor}
        onClick={this.props.onClick}
        onMouseOver={this.props.onMouseOver}
        onMouseLeave={this.props.onMouseLeave}
        marginTop={this.props.marginTop}
        marginBottom={this.props.marginBottom}
        position={this.props.position}
        verticalAlignMiddle={this.props.verticalAlignMiddle}
        borderColor={this.props.borderColor}
      >
        {this.props.iconAlt && <title>{this.props.iconAlt}</title>}
        <Path
          freeIconColor={this.props.freeIconColor}
          iconColor={this.props.iconColor}
          d={this.props.d}
        />
      </Svg>
    );
  }
}

export default SvgIcon;
