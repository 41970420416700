import React from 'react';
import Svg from './Svg';

export default function MouettePicto(props) {
  return (
    <Svg
      {...props}
      d="M839.164 81.7073L763.586 40.5L545.094 434.685L620.25 476.876L839.164 81.7073ZM160.836 334.851L227.657 280.626L509.834 633.37L442.263 686.846L160.836 334.851ZM721.161 653.974L766.068 727.398L379.094 960.5L335.03 886.467L721.161 653.974Z"
    />
  );
}
